import type { DialogEvent, DialogState } from '~/orderingApp/types';

export const initialState: DialogState = {
  context: {
    errorMessage: undefined,
    selectedCartItemId: undefined,
    selectedMenuItemId: undefined,
  },
  stack: [],
  status: 'closed',
  value: '',
};

export const dialogReducer = (prevState: DialogState = initialState, event: DialogEvent) => {
  const nextState = { ...prevState, context: { ...prevState.context } };
  switch (prevState.status) {
    case 'open':
      switch (event.type) {
        case 'ordering.stackDialog':
          nextState.value = event.dialog;
          nextState.context = event.context ?? initialState.context;
          if (prevState.value) {
            nextState.stack = [...prevState.stack, prevState.value];
          }
          break;
        case 'ordering.closeDialog':
          if (prevState.stack[0]) {
            nextState.value = prevState.stack[0];
            nextState.stack = prevState.stack.slice(1);
          } else {
            nextState.value = initialState.value;
            nextState.stack = initialState.stack;
            nextState.status = initialState.status;
            nextState.context = initialState.context;
          }
          break;
        case 'ordering.openDialog':
          nextState.value = event.dialog;
          nextState.stack = initialState.stack;
          break;
        default:
          break;
      }
      break;
    case 'closed':
      switch (event.type) {
        case 'ordering.openDialog':
          nextState.value = event.dialog;
          nextState.status = 'open';
          if (event.context) {
            nextState.context = event.context;
          }
          break;
        case 'autoOpen':
          nextState.value = 'orderInfo.locationSelection';
          nextState.status = 'open';
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return nextState;
};
